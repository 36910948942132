"use client";
import HeroLogo from "@/assets/hero.svg";

const Hero = () => {
  return (
    <div className="relative h-[80vh] overflow-hidden">
      <div className="after-dot">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute w-full h-full object-cover after-dot bg-black"
        >
          <source
            src="https://player.vimeo.com/external/629860961.sd.mp4?s=0c864d123f806a002df1702f79c2554a8d3e504b&profile_id=164"
            type="video/mp4"
          />
        </video>
      </div>

      <div className="relative z-10 flex justify-center items-center h-full">
        <HeroLogo
          className="m-auto"
          alt="basicincome cinemas"
          width={236}
          height={222}
        />
      </div>
    </div>
  );
};

export default Hero;
